const customersWithMoneyRewardConfig = ['rolandberger'];

export const getRewardConfig = (user: AuthUser): RewardConfig => {
  const moneyConfig: RewardConfig = {
    unit: '€',
    unitName: 'Rewards',
    isMoneyReward: true,
  };

  const pointsConfig: RewardConfig = {
    unit: 'P',
    unitName: 'Points',
    isMoneyReward: false,
  };

  const userCompanyID = user['https://ecomio.com/org'];

  return customersWithMoneyRewardConfig.includes(userCompanyID) ? moneyConfig : pointsConfig;
};
