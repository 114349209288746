import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactElement } from 'react';

interface LoaderProps {
  heading: string;
}

const useStyles = makeStyles(() => ({
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 50,
  },
  heading: {
    fontSize: 'calc(1.5vh + 1.5vw)',
  },
}));

export const Loader = (props: LoaderProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.loader}>
      <CircularProgress size={140} />
      <div className={classes.heading}>{props.heading}</div>
    </div>
  );
};
