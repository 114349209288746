import { AdessoInstructions } from '@components/user/instructions/AdessoInstructions';
import { KienbaumInstructions } from '@components/user/instructions/KienbaumInstructions';
import { RBInstructions } from '@components/user/instructions/RBInstructions';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

const useStyles = makeStyles((theme: Theme) => ({
  userInstructionView: {
    width: '100%',
  },
  header: {
    color: theme.palette.secondary.dark,
    fontSize: 30,
    fontWeight: 'bold',
    marginBlock: '20',
  },
  header2: {
    color: theme.palette.secondary.main,
    fontSize: 20,
    fontWeight: 'bold',
    margin: 0,
    paddingBlock: '15px 5px',
  },
  question: {
    fontSize: 16,
    lineHeight: 2,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    lineHeight: 2,
  },
  link: {
    color: theme.palette.secondary.main,
  },
}));

const InstructionsLookupTable: Record<string, JSX.Element> = {
  rolandberger: <RBInstructions />,
  adesso: <AdessoInstructions />,
  kienbaum: <KienbaumInstructions />,
};

const lookupInstruction = (org: string): JSX.Element => InstructionsLookupTable[org] || <></>;

export const UserInstructionView = (): ReactElement => {
  const auth = useAuth();
  if (!auth.user || !auth.user.profile) throw Error('No User found');
  const userProfile = auth?.user?.profile as unknown as AuthUser;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  });

  const howItWorksInstructions = lookupInstruction(userProfile['https://ecomio.com/org']);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.userInstructionView}>
      {howItWorksInstructions}
      <section>
        <h1 className={classes.header}>{t('faqs')}</h1>
        <h2 className={classes.header2}>{t('overall')}</h2>
        <p className={classes.question}>{t('rewardSystemApplicability')}</p>
        <p className={classes.paragraph}>{t('rewardSystemApplicabilityExplanation')}</p>
        <h2 className={classes.header2}>{t('rewards')}</h2>
        <p className={classes.question}>{t('canceledBookings')}</p>
        <p className={classes.paragraph}>{t('canceledBookingsExplanation')}</p>
        <p className={classes.question}>{t('informAboutBooking')}</p>
        <p className={classes.paragraph}>{t('informAboutBookingExplanation')}</p>
        <h2 className={classes.header2}>{t('climateTargetsEcoFriendlyTravels')}</h2>
        <p className={classes.question}>{t('ecoFriendlyTravelRewards')}</p>
        <p className={classes.paragraph}>{t('ecoFriendlyTravelRewardsExplanation')}</p>
        <p className={classes.question}>{t('eurowingsVsLufthansa')}</p>
        <p className={classes.paragraph}>
          {t('eurowingsVsLufthansaExplanation')}{' '}
          <a
            className={classes.link}
            href="https://www.atmosfair.de/en/air_travel_and_climate/atmosfair_airline_index/"
          >
            {t('emissionCalculationLink')}
          </a>
        </p>
        <h2 className={classes.header2}>{t('feedbackSupport')}</h2>
        <p className={classes.question}>{t('feedback')}</p>
        <p className={classes.paragraph}>
          {t('feedbackExplanation')}{' '}
          <a className={classes.link} href="mailto:support@ecomio.com" target="_blank" rel="noreferrer noopener">
            {t('supportEmail')}
          </a>
        </p>
        <p className={classes.question}>{t('support')}</p>
        <p className={classes.paragraph}>{t('supportExplanation')}</p>
      </section>
    </div>
  );
};
