import rewards from '@assets/img/rewards/rolandBergerRewards.webp';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './InstructionStyles';

export const RBInstructions = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <section>
      <h1 className={classes.header}>{t('rolandBergerHeader')}</h1>
      <p className={classes.paragraph}>{t('rolandBergerIntro')}</p>
      <p>
        <b>{t('pleaseNote')}</b>
      </p>
      <ul>
        <li className={classes.listItem}>{t('rolandBergerTopRoutes')}</li>
        <li className={classes.listItem}>{t('rolandBergerApplicableRoutes')}</li>
        <li className={classes.listItem}>{t('rolandBergerExcludedRoutes')}</li>
      </ul>
      <p>{t('rolandBergerRewardInfo')}</p>
      <p>
        <u>{t('rolandBergerExampleRoute')}</u>
      </p>
      <img className={classes.rewards} src={rewards} alt={t('rewardsAltText')} />
      <ul>
        <li className={classes.listItem}>{t('rolandBergerTrainSavings')}</li>
        <li className={classes.listItem}>{t('rolandBergerCashReward')}</li>
        <li className={classes.listItem}>{t('rolandBergerBothWays')}</li>
      </ul>
      <p className={classes.paragraph}>
        {t('rolandBergerEvaluation')}
        <br />
        {t('rolandBergerSupport')}
        <br />
        <b>{t('rolandBergerActTogether')}</b>
      </p>
    </section>
  );
};
