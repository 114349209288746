import '../i18n';

import { Loader } from '@components/general/Loader';
import { createTheme, CssBaseline, StyledEngineProvider, ThemeOptions, ThemeProvider, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as Sentry from '@sentry/react';
import { LandingPage } from '@views/LandingPage';
import { PageNotFound } from '@views/PageNotFound';
import { ReactElement, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { AuthProvider, useAuth } from 'react-oidc-context';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AppContext } from './Context/context';
import { keycloakConfig } from './keycloak';
import { userRoutes } from './routes';
import { getDarkTheme, getLightTheme } from './theme';
import { UserView } from './UserView';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.05, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.NODE_ENV === 'production' ? process.env.SENTRY_ENV : 'development',
});

const useStyles = makeStyles(() => ({
  app: {
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    WebkitFontSmoothing: 'antialised',
    MozOsxFontSmoothing: 'grayscale',
    fontWeight: 300,
    lineHeight: '1.5em',

    '& a': {
      textDecoration: 'none',
    },
  },
}));

const useDarkMode = (): [ThemeOptions, () => void] => {
  const [theme, setTheme] = useState(getLightTheme());

  const toggleTheme = (): void => {
    setTheme(theme.palette?.mode === 'light' ? getDarkTheme() : getLightTheme());
  };

  return [theme, toggleTheme];
};

const App = (props: { toggleTheme: () => void }): ReactElement => {
  const { toggleTheme } = props;
  const theme = useTheme();
  const { isAuthenticated, isLoading } = useAuth();
  const classes = useStyles();

  if (isLoading) {
    return (
      <div className={classes.app}>
        <Loader heading={'Loading...'} />
      </div>
    );
  }

  if (!isAuthenticated) return <LandingPage />;

  return (
    <div className={classes.app}>
      <AppContext>
        <Routes>
          {!isAuthenticated && <Route index element={<LandingPage />} />}
          <Route path="/" element={<UserView toggleTheme={toggleTheme} themeType={`${theme.palette?.mode}`} />}>
            {userRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={<route.component />} />
            ))}
            <Route path="/" element={<Navigate replace to={userRoutes[0].path} />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </AppContext>
    </div>
  );
};

const Wrapper = (): ReactElement => {
  const [theme, toggleTheme] = useDarkMode();

  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(`${process.env.GA_ID_PROD}`);
  } else {
    ReactGA.initialize(`${process.env.GA_ID_DEV}`);
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(theme)}>
        <CssBaseline />
        <BrowserRouter>
          <AuthProvider {...keycloakConfig}>
            <App toggleTheme={toggleTheme} />
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ReactDOM.render(<Wrapper />, document.getElementById('root'));
