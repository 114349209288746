import FlightIcon from '@mui/icons-material/Flight';
import HotelIcon from '@mui/icons-material/Hotel';
import RemoveIcon from '@mui/icons-material/Remove';
import TrainIcon from '@mui/icons-material/Train';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useGlobalContext } from '@src/Context/context';
import moment from 'moment';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { AccordionTableValues } from './AccordionTableValues';

const HotelBookingRow = (props: {
  booking: HotelBookingHistoryDetailItem;
  rewardConfig: RewardConfig;
}): ReactElement => {
  const { booking, rewardConfig } = props;
  return (
    <TableRow>
      <TableCell align="center">
        <HotelIcon />
      </TableCell>
      <TableCell align="center">
        {moment(booking.date_checkin, 'YYYY-MM-DD').format('DD/MM')}
        <br />
        -
        <br />
        {moment(booking.date_checkout, 'YYYY-MM-DD').format('DD/MM')}
      </TableCell>
      <TableCell align="center">
        <RemoveIcon />
      </TableCell>
      <TableCell align="center">{booking.hotel_city}</TableCell>
      <AccordionTableValues booking={booking} rewardConfig={rewardConfig} />
    </TableRow>
  );
};

const TrainBookingRow = (props: {
  booking: TrainBookingHistoryDetailItem;
  rewardConfig: RewardConfig;
}): ReactElement => {
  const { booking, rewardConfig } = props;
  return (
    <TableRow>
      <TableCell align="center">
        <TrainIcon />
      </TableCell>
      <TableCell align="center">
        {booking.travels.map((travel, i) => (
          <div key={i}>{moment(travel.travel_date, 'YYYY-MM-DD').format('DD/MM')}</div>
        ))}
      </TableCell>
      <TableCell align="center">
        {booking.travels.map((travel, i) => (
          <div key={i}>{travel.origin_city}</div>
        ))}
      </TableCell>
      <TableCell align="center">
        {booking.travels.map((travel, i) => (
          <div key={i}>{travel.destination_city}</div>
        ))}
      </TableCell>
      <AccordionTableValues booking={booking} rewardConfig={rewardConfig} />
    </TableRow>
  );
};

const FlightBookingRow = (props: {
  booking: FlightBookingHistoryDetailItem;
  rewardConfig: RewardConfig;
}): ReactElement => {
  const { booking, rewardConfig } = props;
  return (
    <TableRow>
      <TableCell align="center">
        <FlightIcon />
      </TableCell>
      <TableCell align="center">
        {booking.travels.map((travel, i) => (
          <div key={i}>{moment(travel.travel_date, 'YYYY-MM-DD').format('DD/MM')}</div>
        ))}
      </TableCell>
      <TableCell align="center">
        {booking.travels.map((travel, i) => (
          <div key={i}>{travel.origin_city}</div>
        ))}
      </TableCell>
      <TableCell align="center">
        {booking.travels.map((travel, i) => (
          <div key={i}>{travel.destination_city}</div>
        ))}
      </TableCell>
      <AccordionTableValues booking={booking} rewardConfig={rewardConfig} />
    </TableRow>
  );
};

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
});

interface Props {
  index: number;
  rows: BookingHistoryDetailItem[];
  rewardConfig: RewardConfig;
}

export const AccordionTable = (props: Props): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { rows, rewardConfig } = props;
  const globalContext = useGlobalContext();

  return (
    <TableContainer className={classes.table}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">{t('travelDate')}</TableCell>
            <TableCell align="center">{t('origin')}</TableCell>
            <TableCell align="center">{t('destination')}</TableCell>
            {!globalContext?.isSavingsOnly && <TableCell align="center">{rewardConfig.unitName}</TableCell>}
            <TableCell align="center">
              {t('co2Saved')} CO<sub>2</sub>
            </TableCell>
            {!globalContext?.isSavingsOnly && <TableCell align="center">{t('payOutStatus')}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            if (row.type === 'hotel') {
              return (
                <HotelBookingRow booking={row as HotelBookingHistoryDetailItem} key={i} rewardConfig={rewardConfig} />
              );
            }
            if (row.type === 'rail') {
              return (
                <TrainBookingRow booking={row as TrainBookingHistoryDetailItem} key={i} rewardConfig={rewardConfig} />
              );
            }
            if (row.type === 'air') {
              return (
                <FlightBookingRow booking={row as FlightBookingHistoryDetailItem} key={i} rewardConfig={rewardConfig} />
              );
            }
            throw Error(`Invalid booking type for row`);
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
