/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const theme = useTheme();

  const currentLanguage = i18n.language;
  const changeLanguage = async (lng: string): Promise<void> => {
    await i18n.changeLanguage(lng);
  };

  // Inline styles
  const switcherContainerStyle: React.CSSProperties = {
    display: 'flex',
    margin: '10px',
  };

  const buttonStyle: React.CSSProperties = {
    border: 'none',
    color: theme.palette.primary.dark,
    padding: '5px 10px',
    fontSize: '10px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  };

  const buttonHoverStyle: React.CSSProperties = {
    backgroundColor: theme.custom.palette.graphColorTwo.main,
    color: 'white',
  };

  const selectedButtonStyle: React.CSSProperties = {
    backgroundColor: theme.custom.palette.graphColorTwo.main,
    color: 'white',
  };

  const [hovered, setHovered] = React.useState<string | null>(null);

  return (
    <div style={switcherContainerStyle}>
      <button
        style={{
          ...buttonStyle,
          borderRadius: '5px 0 0 5px',
          borderRight: `.5px solid ${theme.palette.primary.dark}`,
          padding: '10px',
          ...(hovered === 'en' ? buttonHoverStyle : {}),
          ...(currentLanguage === 'en' ? selectedButtonStyle : {}),
        }}
        onClick={() => changeLanguage('en')}
        onMouseEnter={() => setHovered('en')}
        onMouseLeave={() => setHovered(null)}
      >
        EN
      </button>
      <button
        style={{
          ...buttonStyle,
          borderRadius: '0 5px 5px 0',
          ...(hovered === 'de' ? buttonHoverStyle : {}),
          ...(currentLanguage === 'de' ? selectedButtonStyle : {}),
        }}
        onClick={() => changeLanguage('de')}
        onMouseEnter={() => setHovered('de')}
        onMouseLeave={() => setHovered(null)}
      >
        DE
      </button>
    </div>
  );
};
