const KEYCLOAK_URL = `https://auth.ecomio.com/realms/${process.env.AUTH_REALM}`;
const CLIENT_ID = process.env.AUTH_CLIENT_ID;
const CLIENT_SECRET = process.env.AUTH_CLIENT_SECRET;

const callback = (): void => {
  window.history.replaceState(null, '', window.location.origin + window.location.pathname);
};

export const keycloakConfig = {
  authority: KEYCLOAK_URL,
  client_id: CLIENT_ID,
  redirect_uri: window.location.origin + window.location.pathname,
  client_secret: CLIENT_SECRET,
  onSigninCallback: callback,
};
