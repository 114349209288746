import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Menu from '@mui/icons-material/Menu';
import { Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import * as Sentry from '@sentry/react';
import { LanguageSwitcher } from '@views/LanguageButton';
import { ReactElement } from 'react';
import ReactGA from 'react-ga4';
import { useAuth } from 'react-oidc-context';

interface INavBarProps {
  toggleTheme: () => void;
  themeType: string;
  handleToggleSidebarMobile: () => void;
  handleToggleSidebar: () => void;
  sidebarOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    width: '100%',
    height: theme.custom.size.navBarHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    padding: '0 15px 0 15px',
    position: 'relative',
    zIndex: 100,
  },
  flexContainer: {},
  menuButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export const NavBar = (props: INavBarProps): ReactElement => {
  const classes = useStyles();
  const auth = useAuth();
  const handleLogout = (): void => {
    auth.signoutRedirect({ post_logout_redirect_uri: `${window.location.origin}` }).catch((error) => {
      Sentry.captureException('Error logging out.');
    });
  };
  return (
    <div className={classes.appBar}>
      <div className={classes.flexContainer}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          className={classes.menuButton}
          onClick={(): void => {
            props.handleToggleSidebarMobile();
            props.handleToggleSidebar();
            ReactGA.event({
              category: 'ROUTE',
              action: props.sidebarOpen === true ? 'Collapsed Side Bar' : 'Expanded Side Bar',
              label: 'Navbar status',
            });
          }}
        >
          <Menu />
        </IconButton>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <LanguageSwitcher />
        <IconButton
          color="inherit"
          aria-label="Toggle Theme"
          onClick={(): void => {
            props.toggleTheme();
            ReactGA.event({
              category: 'SETTINGS',
              action: props.themeType === 'light' ? 'Toggled Dark Mode' : 'Toggled Light Mode',
              label: 'Theme',
            });
          }}
        >
          {props.themeType === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
        </IconButton>
        {process.env.SENTRY_ENV !== 'production' && (
          <IconButton color="inherit" aria-label="Logout" onClick={handleLogout} size="large">
            <ExitToApp />
          </IconButton>
        )}
      </div>
    </div>
  );
};
