import logo from '@assets/img/logos/eco.mio_RGB_white.svg';
import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

const useStyles = makeStyles((theme: Theme) => ({
  landingPage: {
    background: theme.palette.primary.main,
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    position: 'relative',
    backgroundColor: theme.palette.primary.dark,
    height: '75%',
    width: '50%',
    opacity: 0.8,
    borderRadius: 15,
    border: `1.5px solid ${theme.palette.secondary.main}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '5% 0 2.5% 0',

    [theme.breakpoints.down('md')]: {
      height: '100%',
      width: '100%',
      borderRadius: 0,
    },
  },
  imageWrapper: {
    height: '25%',
    width: '50%',
    backgroundImage: `url(${logo})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    [theme.breakpoints.down('md')]: {
      height: '20%',
      width: '45%',
    },
  },
  slogan: {
    color: theme.palette.primary.contrastText,
    fontSize: 'calc(2vh + 1.2vw)',
  },

  button: {
    width: '300px',
    paddingBlock: '1.37em',
  },
}));

export const LandingPage = (): ReactElement => {
  const classes = useStyles();
  const auth = useAuth();

  useEffect(() => {
    // Quick fix so the second login attempt works
    if (!auth.isLoading && !auth.user) {
      // Token exchange failed, remove code and state parameters from the URL
      const baseUrl = window.location.origin + window.location.pathname;
      window.history.replaceState(null, '', baseUrl);
    }
  }, [auth.isLoading, auth.user]);

  return (
    <div className={classes.landingPage}>
      <div className={classes.contentWrapper}>
        <div className={classes.imageWrapper} />
        <div className={classes.slogan}>Reshaping Business Travel</div>
        <Button
          className={classes.button}
          id="loginBtn"
          variant="contained"
          color="secondary"
          onClick={(): void => {
            auth.signinRedirect().catch(() => {
              throw Error('Login with redirect error.');
            });
          }}
        >
          Log In
        </Button>
      </div>
    </div>
  );
};
