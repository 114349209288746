import { Chip, TableCell } from '@mui/material';
import { useGlobalContext } from '@src/Context/context';
import { roundValue } from '@utils/math';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const AccordionTableValues = (props: {
  booking: TrainBookingHistoryDetailItem | FlightBookingHistoryDetailItem | HotelBookingHistoryDetailItem;
  rewardConfig: RewardConfig;
}): ReactElement => {
  const { t } = useTranslation();
  const { booking, rewardConfig } = props;
  const globalContext = useGlobalContext();
  const renderPointStatusChip = (pointStatus: string): ReactElement => (
    <Chip
      label={t(pointStatus)}
      color={pointStatus === 'final' ? 'success' : 'warning'}
      size="small"
      variant="outlined"
    />
  );
  return (
    <>
      {!globalContext?.isSavingsOnly && (
        <TableCell align="center">
          {roundValue(booking.points, 0)} {rewardConfig.unit}
        </TableCell>
      )}
      <TableCell align="center">{`${roundValue(booking.co2_savings, 1)} kg`}</TableCell>
      {!globalContext?.isSavingsOnly && (
        <TableCell align="center">{renderPointStatusChip(booking.point_status)}</TableCell>
      )}
    </>
  );
};
