import kienbaumRewards from '@assets/img/rewards/kienbaumRewards.webp';
import swamp from '@assets/img/swamp.webp';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './InstructionStyles';

export const KienbaumInstructions = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <section>
      <h1 className={classes.header}>{t('kienbaumHeader')}</h1>
      <p className={classes.paragraph}>
        <li className={classes.listItem}>{t('kienbaumItem1')}</li>
        <li className={classes.listItem}>{t('kienbaumItem2')}</li>
        <li className={classes.listItem}>{t('kienbaumItem3')}</li>
      </p>
      <p>
        {t('kienbaumSaveUpTo')}
        <b>{t('kienbaumCO2e')}</b>
      </p>
      <p>
        <u>{t('kienbaumExampleRoute')}</u>
      </p>
      <img className={classes.rewards} src={kienbaumRewards} alt={t('kienbaumRewardsAltText')} />
      <ul>
        <li className={classes.listItem}>{t('kienbaumTrainRouteSavings')}</li>
        <li className={classes.listItem}>{t('kienbaumCollectPoints')}</li>
        <li className={classes.listItem}>{t('kienbaumCheaperOptionPoints')}</li>
        <li className={classes.listItem}>{t('kienbaumPointsToDonations')}</li>
      </ul>
      <img className={classes.swamp} src={swamp} alt={t('swampAltText')} />
      <p className={classes.paragraph}>
        {t('kienbaumDonateAmount')}
        <a href="https://nrw.nabu.de/spenden-und-mitmachen/spenden/wildesnrw.html">{t('kienbaumNABU')}</a>
        {t('kienbaumWetlandsDescription')}
        <br />
        {t('kienbaumEvaluate')}
        <br />
        <b>{t('kienbaumJoinForces')}</b>
      </p>
    </section>
  );
};
