import adessoRewards from '@assets/img/rewards/adessoRewards.webp';
import swamp from '@assets/img/swamp.webp';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './InstructionStyles';

export const AdessoInstructions = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <section>
      <h1 className={classes.header}>{t('adessoHowItWorksHeader')}</h1>
      <p className={classes.paragraph}>
        <li className={classes.listItem}>{t('adessoHowItWorksItem1')}</li>
        <li className={classes.listItem}>{t('adessoHowItWorksItem2')}</li>
        <li className={classes.listItem}>{t('adessoHowItWorksItem3')}</li>
      </p>
      <p>
        <b>{t('adessoHowItWorksNote')}</b>
      </p>
      <p>
        {t('adessoHowItWorksSaveUpTo')}
        <b>{t('adessoHowItWorksCO2e')}</b>
      </p>
      <p>
        <u>{t('adessoHowItWorksExampleRoute')}</u>
      </p>
      <img className={classes.rewards} src={adessoRewards} alt={t('rewardsAltText')} />
      <ul>
        <li className={classes.listItem}>{t('adessoHowItWorksTrainRouteSavings')}</li>
        <li className={classes.listItem}>{t('adessoHowItWorksCollectPoints')}</li>
        <li className={classes.listItem}>{t('adessoHowItWorksPointsToDonations')}</li>
      </ul>
      <img className={classes.swamp} src={swamp} alt={t('swampAltText')} />
      <p className={classes.paragraph}>
        {t('adessoHowItWorksDonateAmount')}
        <a href="https://www.stiftungsland.de/was-wir-tun/entwicklungshelfer/moorschutz/?L=3">
          {t('adessoHowItWorksSwamps')}
        </a>
        {t('adessoHowItWorksSwampDescription')}
        <br />
        {t('adessoHowItWorksEvaluate')}
        <br />
        <b>{t('adessoHowItWorksJoinForces')}</b>
      </p>
    </section>
  );
};
