import GilroyWoff2Bold from '@assets/fonts/GilroyBold/Gilroy-Bold.woff2';
import GilroyWoff2 from '@assets/fonts/GilroyRegular/Gilroy-Regular.woff2';
import { ThemeOptions } from '@mui/material/';

const baseLightTheme: ThemeOptions = {
  palette: {
    primary: {
      light: '#5c667a',
      main: '#1c2730',
      dark: '#0b1626',
      contrastText: '#fff',
    },
    secondary: {
      light: '#9bb1ff',
      main: '#6482f5',
      dark: '#2156c1',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#f6f6f6',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
    },
    mode: 'light',
  },
  typography: {
    fontFamily: ['Gilroy', 'Arial'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Gilroy';
        font-style: normal;
        font-display: swap;
        font-weight: normal;
        src: local('Gilroy'), local('Gilroy-Regular'), url(${GilroyWoff2}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      @font-face {
        font-family: 'Gilroy';
        font-style: normal;
        font-display: swap;
        font-weight: bold;
        src: local('Gilroy-Bold'), url(${GilroyWoff2Bold}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
    `,
    },
  },
  custom: {
    palette: {
      graphColorOne: {
        light: '#5c667a',
        main: '#323c4e',
        dark: '#0b1626',
      },
      graphColorTwo: {
        light: '#9bb1ff',
        main: '#6482f5',
        dark: '#2156c1',
      },
      history: {
        summaryBorder: '#e0e0e0',
      },
    },
    size: {
      drawerWidthOpen: 260,
      drawerWidthClosed: 70,
      navBarHeight: 60,
    },
    transition: {
      drawerTransition: 'all 0.25s cubic-bezier(0.685, 0.0473, 0.346, 1)',
    },
  },
};

const baseDarkTheme: ThemeOptions = {
  ...baseLightTheme,
  palette: {
    primary: {
      light: '#9bb1ff',
      main: '#6482f5',
      dark: '#2156c1',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#000',
    },
    background: {
      paper: '#383838',
      default: '#181818',
    },
    mode: 'dark',
  },
  custom: {
    ...baseLightTheme.custom,
    palette: {
      graphColorOne: {
        light: '#9bb1ff',
        main: '#6482f5',
        dark: '#2156c1',
      },
      graphColorTwo: {
        light: '#9bb1ff',
        main: '#6482f5',
        dark: '#2156c1',
      },
      history: {
        summaryBorder: '#515151',
      },
    },
  },
};

const dltLightTheme: ThemeOptions = {
  palette: {
    primary: {
      light: '#5c667a',
      main: '#000',
      dark: '#0b1626',
      contrastText: '#fff',
    },
    secondary: {
      light: '#63666a',
      main: '#26890D',
      dark: '#046A38',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#f6f6f6',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
    },
    mode: 'light',
  },
  typography: {
    fontFamily: ['Gilroy', 'Arial'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Gilroy';
        font-style: normal;
        font-display: swap;
        font-weight: normal;
        src: local('Gilroy'), local('Gilroy-Regular'), url(${GilroyWoff2}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      @font-face {
        font-family: 'Gilroy';
        font-style: normal;
        font-display: swap;
        font-weight: bold;
        src: local('Gilroy-Bold'), url(${GilroyWoff2Bold}) format('woff2');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
    `,
    },
  },
  custom: {
    palette: {
      graphColorOne: {
        light: '#63666a',
        main: '#007CB0',
        dark: '#0b1626',
      },
      graphColorTwo: {
        light: '#63666a',
        main: '#26890D',
        dark: '#2156c1',
      },
      history: {
        summaryBorder: '#e0e0e0',
      },
    },
    size: {
      drawerWidthOpen: 260,
      drawerWidthClosed: 70,
      navBarHeight: 60,
    },
    transition: {
      drawerTransition: 'all 0.25s cubic-bezier(0.685, 0.0473, 0.346, 1)',
    },
  },
};

const dltDarkTheme: ThemeOptions = {
  ...dltLightTheme,
  palette: {
    primary: {
      light: '#9bb1ff',
      main: '#6482f5',
      dark: '#2156c1',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#000',
    },
    background: {
      paper: '#383838',
      default: '#181818',
    },
    mode: 'dark',
  },
  custom: {
    ...dltLightTheme.custom,
    palette: {
      graphColorOne: {
        light: '#9bb1ff',
        main: '#6482f5',
        dark: '#2156c1',
      },
      graphColorTwo: {
        light: '#9bb1ff',
        main: '#6482f5',
        dark: '#2156c1',
      },
      history: {
        summaryBorder: '#515151',
      },
    },
  },
};

export function getLightTheme(): ThemeOptions {
  const themeName = process.env.THEME;
  switch (themeName) {
    case 'deloitte':
      return dltLightTheme;
    default:
      return baseLightTheme;
  }
}

export function getDarkTheme(): ThemeOptions {
  const themeName = process.env.THEME;
  switch (themeName) {
    case 'deloitte':
      return dltDarkTheme;
    default:
      return baseDarkTheme;
  }
}
