import { CustomTooltip } from '@components/general/CustomTooltip';
import { Loader } from '@components/general/Loader';
import { CustomCard } from '@customizations/CardCustomization';
import HelpIcon from '@mui/icons-material/Help';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { HistoryAccordion } from '@src/components/user/history/HistoryAccordion';
import { useGlobalContext } from '@src/Context/context';
import { BookingsRepository } from '@src/repositories/bookings.repository';
import { getRewardConfig } from '@utils/getRewardConfig';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

const GreenBackgroundIcon = styled(HelpIcon)({
  backgroundColor: '#99c78c',
  borderRadius: '50%',
  padding: '5px',
  color: 'white',
  margin: '0 10px',
});

const useStyles = makeStyles(() => ({
  title: {
    paddingLeft: '16px',
  },
  headingMonth: {
    width: '20%',
    position: 'relative',
    display: 'inline-block',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  headingBookings: {
    width: '30%',
    position: 'relative',
    display: 'inline-block',
    fontWeight: 'bold',
  },
  headingRewards: {
    width: '30%',
    position: 'relative',
    display: 'inline-block',
    fontWeight: 'bold',
  },
  pendingRewards: {
    width: '30%',
    position: 'relative',
    fontWeight: 'bold',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  headingCo2: {
    width: '20%',
    position: 'relative',
    display: 'inline-block',
    fontWeight: 'bold',
    textAlign: 'right',
    paddingRight: 10,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '16px',
    paddingRight: '40px',
    textAlign: 'center',
    width: 'auto',
  },
  loaderWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export const UserHistoryView = (): ReactElement => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [rows, setRows] = useState<BookingHistoryItem[]>([]);
  const globalContext = useGlobalContext();
  const classes = useStyles();
  const auth = useAuth();
  if (!auth.user || !auth.user.profile) throw Error('No User found');
  const userProfile = auth.user.profile as unknown as AuthUser;
  const rewardConfig = getRewardConfig(userProfile);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        if (!auth.user) throw Error('No User found');
        const accessToken = auth.user.access_token;
        const data = await BookingsRepository.getBookingHistory(accessToken);
        setRows(data);
        setLoaded(true);
      } catch (e) {
        if (process.env.NODE_ENV === 'development') console.log(e);
        alert(
          'We went searching long and wide all over the web, but our server seems to be in flight mode... errm train mode, I mean...\nPlease check back later - or leave us a message at support@ecomio.com.\nThanks for your understanding!',
        );
      }
    }

    fetchData().catch(() => {
      throw new Error('Data Fetching went wrong');
    });
  }, []);
  return (
    <div>
      {loaded ? (
        <CustomCard>
          <h2 className={classes.title}>{t('travelHistory')}</h2>
          <div className={classes.headerWrapper}>
            <div className={classes.headingMonth}>{t('month')}</div>
            <div className={classes.headingBookings}>{t('noOfBookings')}</div>
            {!globalContext?.isSavingsOnly && (
              <div className={classes.headingRewards}>
                {rewardConfig.unitName === 'Points' ? t('totalPoints') : t('totalRewards')}
              </div>
            )}
            <div className={classes.pendingRewards}>
              {rewardConfig.unitName === 'Points' ? t('pendingPoints') : t('pendingRewards')}
              <CustomTooltip
                title={t('pendingRewardsDescription', { rewards: t(rewardConfig.unitName.toLocaleLowerCase()) })}
              >
                <GreenBackgroundIcon />
              </CustomTooltip>
            </div>
            <div className={classes.headingCo2}>
              {t('co2Saved')} CO<sub>2</sub>
            </div>
          </div>
          <div>
            {rows.map((row, index) => (
              <HistoryAccordion key={index} historyItem={row} index={index} rewardConfig={rewardConfig} />
            ))}
          </div>
        </CustomCard>
      ) : (
        <div className={classes.loaderWrapper}>
          <Loader heading={t('loadingHistory')} />
        </div>
      )}
    </div>
  );
};
