import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { AdminUserView } from '@views/admin/AdminUserView';
import { UserDashboardView } from '@views/user/UserDashboardView';
import { UserHistoryView } from '@views/user/UserHistoryView';
import { UserInstructionView } from '@views/user/UserInstructionsView';
import { ReactElement } from 'react';

export interface Route {
  path: string;
  name: string;
  component: () => ReactElement;
  layout: string;
  icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
}

// All the routes as seen in the side bar of the website
export const userRoutes: Route[] = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: UserDashboardView,
    layout: '/user',
    icon: DashboardRoundedIcon,
  },
  {
    path: '/history',
    name: 'history',
    component: UserHistoryView,
    layout: '/user',
    icon: HistoryRoundedIcon,
  },
  {
    path: '/faq',
    name: 'howItWorks',
    component: UserInstructionView,
    layout: '/user',
    icon: HelpRoundedIcon,
  },
];

export const adminRoutes: Route[] = [
  {
    path: '/user_management',
    name: 'User',
    component: AdminUserView,
    layout: 'admin',
    icon: GroupsRoundedIcon,
  },
];
