import { CustomerName } from '@src/variables/enums';
import { useAuth } from 'react-oidc-context';

export const getCompanyName = (): string => {
  const auth = useAuth();

  const profile = auth?.user?.profile as { [key: string]: string | undefined };
  const orgName = profile?.['https://ecomio.com/org'];
  const name = orgName && orgName in CustomerName ? CustomerName[orgName as keyof typeof CustomerName] : 'Company';

  return name;
};
