export type RewardType = 'donation' | 'fixed' | 'points' | 'raffle' | 'savings';

export const ORG_REWARD_MAP: Record<string, RewardType> = {
  adesso: 'raffle',
  bearingPoint: 'points',
  kearney: 'points',
  kienbaum: 'points',
  noerr: 'savings',
  rhenus: 'donation',
  rolandberger: 'fixed',
};

export enum CustomerName {
  'ecomio' = 'eco.mio',
  'rolandberger' = 'Roland Berger',
  'adesso' = 'adesso',
  'kienbaum' = 'Kienbaum',
  'kearney' = 'Kearney',
  'bearingpoint' = 'Bearing Point',
  'rhenus' = 'Rhenus',
  'noerr' = 'Noerr',
}
