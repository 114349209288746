import star from '@assets/img/star.svg';
import sustainability from '@assets/img/sustainability.svg';
import { Loader } from '@components/general/Loader';
import { GeneralOverviewCard } from '@components/user/dashboard/GeneralOverviewCard';
import { MonthlyBarOverview } from '@components/user/dashboard/MonthlyBarOverview';
import { Grid, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BookingsRepository } from '@repositories/bookings.repository';
import { useGlobalContext } from '@src/Context/context';
import { getDates, getMonthName } from '@utils/date';
import { getRewardConfig } from '@utils/getRewardConfig';
import { roundValue } from '@utils/math';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  dashboardWrapper: {
    width: '100%',
  },
}));

const createDefaultTotalReward = (): TotalPoints => {
  return {
    total_points_user: 0,
    dateframe_points_user: 0,
    dateframe_points_company: 0,
    total_co2_user: 0,
    non_negative_co2_user: 0,
    dateframe_co2_user: 0,
    dateframe_co2_company: 0,
  };
};

export const UserDashboardView = (): ReactElement => {
  const { t } = useTranslation();
  const auth = useAuth();
  if (!auth.user || !auth.user.profile) throw Error('No User found');
  const userProfile = auth.user.profile as unknown as AuthUser;
  const rewardConfig = getRewardConfig(userProfile);

  const globalContext = useGlobalContext();
  const classes = useStyles();
  const theme = useTheme();
  const [totalPoints, setTotalPoints] = useState<TotalPoints>(createDefaultTotalReward());
  const dates = getDates();
  const [pointValues, setPointValues] = useState<{ title: string; value: number }[]>([]);
  const [co2Values, setCO2Values] = useState<{ title: string; value: number }[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        if (!auth.user) throw Error('No User found');
        const accessToken = auth.user.access_token;
        const fetchedTotalPoints = await BookingsRepository.getTotalPoints(
          accessToken,
          dates.monthStart,
          dates.monthEnd,
        );

        const pointHistory = await BookingsRepository.getPointHistory(accessToken, dates.monthEnd);

        const points = pointHistory.map((pointHistoryItem) => {
          return {
            title: `${getMonthName(pointHistoryItem.month)} ${pointHistoryItem.year}`,
            value: pointHistoryItem.total_points,
          };
        });

        const co2Savings = pointHistory.map((pointHistoryItem) => {
          return {
            title: `${getMonthName(pointHistoryItem.month)} ${pointHistoryItem.year}`,
            value: pointHistoryItem.non_negative_savings,
          };
        });
        setTotalPoints(fetchedTotalPoints);
        setPointValues(points);
        setCO2Values(co2Savings);
        setLoaded(true);
      } catch (e) {
        if (process.env.NODE_ENV === 'development') console.log(e);
      }
    }

    fetchData().catch(() => {
      throw new Error('Data Fetching went wrong');
    });
  }, []);
  const gridMediumWidth = !globalContext?.isSavingsOnly ? 6 : 12;
  return (
    <div className={classes.dashboardWrapper}>
      {loaded ? (
        <Grid container spacing={3}>
          {!globalContext?.isSavingsOnly && (
            <Grid item xs={12} md={gridMediumWidth}>
              <GeneralOverviewCard
                cardTitle={t('welcome')}
                colors={[theme.custom.palette.graphColorOne.main, theme.custom.palette.graphColorOne.light]}
                unit={rewardConfig.unit}
                icon={star}
                monthlyUser={roundValue(totalPoints.dateframe_points_user, 2)}
                monthlyCompany={roundValue(totalPoints.dateframe_points_company, 2)}
                totalUser={roundValue(totalPoints.total_points_user, 2)}
              />
            </Grid>
          )}
          <Grid item xs={12} md={gridMediumWidth}>
            <GeneralOverviewCard
              cardTitle={t('totalCO2Savings')}
              colors={[theme.custom.palette.graphColorTwo.main, theme.custom.palette.graphColorTwo.light]}
              unit="kg"
              icon={sustainability}
              monthlyUser={roundValue(totalPoints.dateframe_co2_user, 1)}
              monthlyCompany={roundValue(totalPoints.dateframe_co2_company, 1)}
              totalUser={roundValue(totalPoints.non_negative_co2_user, 1)}
            />
          </Grid>
          {!globalContext?.isSavingsOnly && (
            <Grid item xs={12} md={gridMediumWidth}>
              <NavLink to="/history">
                <MonthlyBarOverview
                  cardTitle={t('travelHistory')}
                  label={t('myMonthlyRewards')}
                  color={theme.custom.palette.graphColorOne.main}
                  values={pointValues.map((pointValue) => {
                    return { title: pointValue.title, value: roundValue(pointValue.value, 2) };
                  })}
                  unit={rewardConfig.unit}
                />
              </NavLink>
            </Grid>
          )}
          <Grid item xs={12} md={gridMediumWidth}>
            <NavLink to="/history">
              <MonthlyBarOverview
                cardTitle={t('co2SavingsHistory')}
                label={t('myMonthlySavings')}
                color={theme.custom.palette.graphColorTwo.main}
                values={co2Values.map((co2Value) => {
                  return { title: co2Value.title, value: roundValue(co2Value.value, 1) };
                })}
                unit="kg"
              />
            </NavLink>
          </Grid>
        </Grid>
      ) : (
        <Loader heading={t('loadingDashboard')} />
      )}
    </div>
  );
};
