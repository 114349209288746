import { BackendRepository } from './backend.repository';

const resource = '/bookings';

interface TotalPointsResponse {
  data: TotalPoints;
}

interface PointHistoryResponse {
  data: PointHistory[];
}

interface BookingHistoryResponse {
  data: BookingHistoryItem[];
}

export const BookingsRepository = {
  async getTotalPoints(accessToken: string, dateFrom: Date, dateTo: Date): Promise<TotalPoints> {
    const { data }: TotalPointsResponse = await BackendRepository.get(`${resource}/booking_data/`, {
      headers: { authorization: `Bearer ${accessToken}` },
      params: {
        date_from: dateFrom.toISOString().substring(0, 10),
        date_to: dateTo.toISOString().substring(0, 10),
      },
    });

    return data;
  },

  async getPointHistory(accessToken: string, dateTo: Date): Promise<PointHistory[]> {
    const { data }: PointHistoryResponse = await BackendRepository.get(`${resource}/point_history/`, {
      headers: { authorization: `Bearer ${accessToken}` },
      params: {
        date_to: dateTo.toISOString().substring(0, 10),
      },
    });

    return data;
  },

  async getBookingHistory(accessToken: string): Promise<BookingHistoryItem[]> {
    const { data }: BookingHistoryResponse = await BackendRepository.get(`${resource}/booking_history/`, {
      headers: { authorization: `Bearer ${accessToken}` },
    });

    return data;
  },
};
