import { ORG_REWARD_MAP } from '@src/variables/enums';
import { createContext, useContext } from 'react';
import { useAuth } from 'react-oidc-context';

interface AppContextProviderProps {
  children: React.ReactNode;
}

interface AppContextType {
  isSavingsOnly: boolean | undefined;
}

const GlobalContext = createContext<AppContextType | undefined>(undefined);
export const useGlobalContext = (): AppContextType | undefined => useContext(GlobalContext);

export const AppContext = (props: AppContextProviderProps): JSX.Element => {
  const auth = useAuth();
  if (!auth.user || !auth.user.profile) throw Error('No User found');
  const org = auth.user.profile['https://ecomio.com/org'];
  if (!org || typeof org !== 'string') throw Error('No Org found');
  const rewardType = ORG_REWARD_MAP[org];
  const isSavingsOnly = rewardType === 'savings';

  return <GlobalContext.Provider value={{ isSavingsOnly }}>{props.children}</GlobalContext.Provider>;
};
