import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    color: theme.palette.secondary.dark,
    fontSize: 30,
    fontWeight: 'bold',
    marginBlock: '20',
    lineHeight: 1.2,
  },
  paragraph: {
    fontSize: 16,
    lineHeight: 2,
  },
  listItem: {
    marginBottom: 10,
  },
  rewards: {
    width: 800,
    height: 'auto',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  swamp: {
    width: 800,
    height: 'auto',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
}));
