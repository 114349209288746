import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  adminUserView: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const AdminUserView = (): ReactElement => {
  const classes = useStyles();
  return <div className={classes.adminUserView}>User</div>;
};
