import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    textAlign: 'center',
    fontSize: '14px',
    '& :first-child': {
      marginBottom: '14px',
      borderLeft: 0,
    },
  },
  link: {
    color: theme.palette.secondary.main,
  },
}));

export const Footer = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <footer className={classes.footer}>
      <hr />
      {t('contactIssue')}
      <a className={classes.link} href="mailto:support@ecomio.com" target="_blank" rel="noreferrer noopener">
        {t('supportFooter')}
      </a>
    </footer>
  );
};
