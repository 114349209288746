export const getMonthName = (index: number): string => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (index > 12 || index < 1) throw Error('getMonthName called with invalid index');
  return months[index - 1];
};

export const getDates = (): { monthStart: Date; today: Date; monthEnd: Date } => {
  const today = new Date();
  // When we create a date with a timezone shift, conversion to ISO string may take the wrong day
  // as it works with UTC time. To avoid this, we set the time to 12:00:00
  const monthStart = new Date(today.getFullYear(), today.getMonth(), 1, 12);
  const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 12);
  return { monthStart, today, monthEnd };
};
