import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useGlobalContext } from '@src/Context/context';
import { getMonthName } from '@utils/date';
import { roundValue } from '@utils/math';
import { ReactElement } from 'react';

import { AccordionTable } from './AccordionTable';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  accordionWrapper: {
    boxShadow: 'none',
    '&.MuiAccordion-root': {
      borderRadius: 0,
    },
  },
  accordionSummary: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    textAlign: 'center',
    borderTop: `1px solid ${theme.custom.palette.history.summaryBorder}`,
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  monthColumn: {
    width: '20%',
    textAlign: 'left',
  },
  bookingsColumn: {
    width: '30%',
  },
  rewardsColumn: {
    width: '30%',
  },
  co2Column: {
    width: '20%',
    textAlign: 'right',
    paddingRight: 10,
  },
  title: {},
}));

interface IHistoryAccordionProps {
  index: number;
  historyItem: BookingHistoryItem;
  rewardConfig: RewardConfig;
}
export const HistoryAccordion = (props: IHistoryAccordionProps): ReactElement => {
  const globalContext = useGlobalContext();
  const { historyItem, index, rewardConfig } = props;
  const classes = useStyles();
  return (
    <div>
      <Accordion className={classes.accordionWrapper}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-content-${index}`}
          id={`panel-header-${index}`}
        >
          <div className={classes.monthColumn}>
            {`${getMonthName(historyItem.month).substring(0, 3)} ${historyItem.year}`}
          </div>
          <div className={classes.bookingsColumn}>{historyItem.bookings.length}</div>
          {!globalContext?.isSavingsOnly && (
            <div className={classes.rewardsColumn}>
              {roundValue(historyItem.total_points, 0)} {rewardConfig.unit}
            </div>
          )}
          {!globalContext?.isSavingsOnly && (
            <div className={classes.rewardsColumn}>
              {roundValue(historyItem.pending_rewards, 0)} {rewardConfig.unit}
            </div>
          )}
          <div className={classes.co2Column}>{`${roundValue(historyItem.non_negative_co2_savings, 1)} kg`}</div>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionTable rows={historyItem.bookings} index={index} rewardConfig={rewardConfig} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
